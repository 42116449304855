body {
  background-color: #fbfbfb !important;
}

.navBar:hover {
  background-color: #ffffff !important;
}

.otherResources {
  height: 100%;
  background-color: #fbfbfb;
}

.otherResources .navBar .MuiTypography-body1 {
  letter-spacing: 0px;
}

.otherResources .topBar {
  padding-left: 0.8vw !important;
}

.otherResources .link {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.otherResources .body {
  background-color: #fbfbfb;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
}

.otherResources .fastLinks {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
}

.otherResources .fastLink {
  border-bottom: 1px solid #0f6493;
  color: #0f6493;
  text-decoration: none;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}

.otherResources .fastLink:hover {
  background-color: #0f6493;
  color: white;
}

.otherResources .arrow {
  margin-left: 5px;
  transform: scale(1.3);
  padding-bottom: 3px;
}

.otherResources .MuiCard-root {
  background-color: #eeeeee !important;
  margin-bottom: 20px;
  width: 800px;
}

.otherResources .MuiTypography-h4 {
  text-align: center;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.otherResources .MuiTypography-body2 {
  margin-bottom: 10px !important;
}

.otherResources .MuiTypography-root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

.otherResources .educationalResources {
  margin-bottom: 90vh;
}

#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
}

.otherResources .moveTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  color: #b21e8c;
  width: 30px;
  height: auto;
}

.otherResources .mobileHamburger,
.otherResources .mobileMenu {
  display: none;
}

.otherResources .drop-down-title p {
  margin-bottom: 0px !important;
}


@media screen and (max-width: 930px) and (orientation: landscape) {
  .otherResources .topBar {
    padding-top: 0px !important;
    padding-left: 0px !important;
  }

  .otherResources .navBar {
    min-width: 0px !important;
    margin-top: 1px !important;
  }

  .otherResources .arrow {
    margin-left: 0.5vmin;
    padding-bottom: 0.5vmin;
  }

  .otherResources .MuiTypography-h4 {
    font-size: 5vmin !important;
    margin-top: 2vh !important;
    margin-bottom: 2vh !important;
  }

  .otherResources .fastLinks {
    font-size: 2vmin;
    margin-bottom: 4vh;
  }

  .otherResources .dropDown {
    width: 100vw;
    width: calc(var(--vw, 1vw) * 80);
    margin-bottom: 3vh;
  }

  .otherResources .MuiTypography-h6 {
    font-size: 3vmin !important;
  }

  .otherResources .MuiTypography-body2 {
    margin-bottom: 1vh !important;
    font-size: 2vmin !important;
  }

  .otherResources .otherResourceContent {
    padding: 3vh 16px !important;
  }

  .otherResources .mobileHamburger,
  .otherResources .mobileMenu {
    display: none;
  }
}

@media screen and (orientation: portrait) {
  .otherResources .topBar {
    padding-top: 0px !important;
    padding-left: 0px !important;
    min-height: 8vh !important;
    max-height: 8vh !important;
  }

  .otherResources .navBar {
    display: none !important;
  }

  .otherResources .mobileHamburger {
    color: black;
    height: auto;
    width: 8vmin;
    display: inline;
    position: absolute;
    right: 10px;
  }

  .otherResources .mobileMenu {
    position: fixed;
    z-index: 1500;
    right: -100vw;
    width: 100vw;
    height: 100vh;
    background: #fbfbfb;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: all 1s ease;
  }

  .otherResources .active {
    right: 0;
  }

  .otherResources .body {
    padding: 0 2vw;
    margin: 0;
  }

  .otherResources .MuiTypography-h4 {
    margin: 3vh 0 !important;
    font-size: 7vmin !important;
  }

  .otherResources .MuiTypography-h6 {
    font-size: 4vmin !important;
  }

  .otherResources .MuiTypography-body2 {
    font-size: 3.5vmin !important;
  }

  .otherResources .fastLinks {
    display: none;
  }

  .otherResources .findingDistributors,
  .otherResources .UsaMarket,
  .otherResources .gettingReviewed,
  .otherResources .educationalResources {
    width: 100%;
  }

  .otherResources .educationalResources {
    margin-bottom: 60px;
  }

  .otherResources .MuiCard-root {
    width: 100% !important;
  }

  .otherResources .MuiListItemButton-gutters {
    padding: 8px 5px !important;
  }
}
