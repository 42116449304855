.stateList-body {
  margin: 0px 5%;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.checkbox-label {
  display: flex;
  align-items: center; 
  margin-right: 10px; 
  min-width: 160px;
}

#saveBtn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 13px;
  cursor: pointer;
  border-radius: 5px;
}

#saveBtn:hover {
  background-color: #0056b3;
}
