.MuiGrid-root {
  height: 100%;
}

.map-container {
  padding-left: 5px !important;
  background: #fefefe;
}

.moved-badge .MuiBadge-badge {
  transform: scale(1) translate(100%, -100%);
}

.modal {
  z-index: 1111 !important;
}

.modal-backdrop {
  z-index: 1110 !important;
}

.modal-content {
  padding: 10px !important;
}

.modal-footer {
  padding: 10px 0px 0px 0px !important;
}

.modal-body {
  padding: 5px !important;
}

.modal-body p {
  margin-bottom: 5px !important;
}

.modal-dialog-centered {
  max-width: 60vw !important;
}

#basic-button {
  margin-bottom: 10px;
}

.containedData {
  padding-left: 5px !important;
}

.main-page-section-containers {
  height: 100%;
  margin-bottom: 0;
}

.MuiAlert-message {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  padding: 0px !important;
  font-size: 13px !important;
}

.MuiSnackbar-anchorOriginTopRight {
  margin-top: 22px !important;
}

.MuiSnackbar-anchorOriginTopRight .MuiAlert-root {
  padding: 3px 7px !important;
  display: flex;
  align-items: center;
}

.MuiAlert-icon {
  margin-right: 5px !important;
}

.MuiAlert-action {
  padding: 4px 0 0 4px !important;
  margin-left: -6px !important;
}

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

.modal-video-body {
  max-width: 60vw;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -30px;
  right: 0px;
  display: inline-block;
  width: 20px;
  overflow: hidden;
  border: none;
  background: transparent;
}

.helpRecording {
  color: #0d6efd;
  text-decoration: underline;
  cursor: pointer;
}

.removeState,
.addState,
.printState {
  margin-right: 10px !important;
}

@media screen and (max-width: 1370px) and (orientation: landscape) {
  .MuiSnackbar-anchorOriginTopRight {
    margin-top: 50px !important;
  }
}

@media screen and (max-width: 930px) and (orientation: landscape) {
  #contained-modal-title-vcenter {
    font-size: 3vmin !important;
  }

  #contained-modal-title-vcenter p {
    margin-bottom: 0px !important;
  }

  .modal-body .productList {
    margin-bottom: 5px !important;
  }

  .modal-body .popUpFilterList {
    margin-bottom: 5px !important;
  }

  .btn-close {
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
      center/3vmin auto no-repeat !important;
  }

  .modal-footer {
    padding: 5px 0px 0px 0px !important;
  }

  .welcomeClose {
    padding: 0px !important;
  }

  .welcomeHeader {
    padding: 0px 5px 0px;
  }

  .welcomeContent .modal-content {
    padding: 5px !important;
  }

  .modal-dialog-centered {
    max-width: 70vw !important;
  }

  .statePopupHeader {
    align-items: center;
  }

  .headerText {
    font-size: 2vmin !important;
  }

  .statePopupClose {
    height: auto !important;
    width: 3vmin !important;
  }

  .removeState,
  .addState,
  .printState {
    margin-top: 2vmin !important;
    padding: 0px !important;
    height: 6vh !important;
    margin-right: 0px !important;
  }
}

@media screen and (orientation: portrait) {
  .mapPage {
    height: calc(100% + 16px) !important;
  }

  .welcomeContent .modal-dialog-centered {
    max-width: 99vw !important;
  }

  .welcomeHeader .modal-title {
    font-size: 3.4vmin !important;
  }

  .welcomeHeader .modal-title p {
    margin-bottom: 0px !important;
  }

  .welcomeBody {
    font-size: 2.4vmin !important;
  }

  .welcomeBody .productList {
    margin-bottom: 1vmin !important;
  }

  .welcomeBody .popUpFilterList {
    margin-bottom: 1vmin !important;
  }

  .welcomeClose {
    width: 30vw !important;
  }

  .welcomeClose .MuiTypography-body2 {
    font-size: 2.4vmin !important;
  }

  .filterContent {
    padding: 3px 5px !important;
  }

  .filterContent .helper {
    width: 3vmin !important;
  }

  .modal-backdrop {
    z-index: 1210 !important;
  }

  .welcomeContent {
    z-index: 1211 !important;
  }

  .filterPopupContent {
    z-index: 1300 !important;
  }

  .filterPopupContent .modal-dialog-centered {
    max-width: 99vw !important;
  }

  .filterPopupHeader {
    padding: 0px 5px 5px !important;
  }

  .filterPopupTitle {
    font-size: 3.4vmin !important;
  }

  .filterPopupBody {
    font-size: 2.4vmin !important;
  }

  .filterPopupFooter {
    padding: 0 !important;
  }

  .state-container {
    padding-left: 0px !important;

    z-index: 1100 !important;
    position: fixed !important;
    background: white !important;
    transition: all 1s ease !important;
    width: 100vw !important;
    max-width: 100vw !important;
    margin-top: 16px !important;
    left: -100vw;
  }

  .containedData {
    left: 0;
  }

  .map-container {
    width: 98vw !important;
    margin: 0 !important;
    padding-left: 0px !important;
  }

  .mapPage {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    width: 100vw !important;
  }

  .map-container {
    width: 100vw !important;
    margin: 0 !important;
    padding-left: 0px !important;
  }

  .removeState,
  .addState,
  .printState {
    display: none !important;
  }

  .statePopupHeader .headerText {
    font-size: 6vmin !important;
    margin-left: 0px !important;
  }

  .statePopupClose {
    height: auto !important;
    width: 8vmin !important;
    margin-right: 10px !important;
  }

  .statePopupSubtitle {
    font-size: 3.5vmin !important;
  }

  .statePopupSubtitle .MuiSvgIcon-fontSizeMedium {
    height: auto !important;
    width: 4vmin !important;
    margin-left: 5px !important;
  }

  .main-page-section-containers .section-container {
    margin: 2.5vh 0px 2.5vh 0px !important;
  }

  .statePopupFigure {
    font-size: 3.5vmin !important;
  }

  .modal-video-body {
    max-width: unset;
  }
}
