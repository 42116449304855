#root .state-selector-dropdown div {
  padding: 13px;
}

.binIcon {
  cursor: pointer;
  margin-right: 5px;
  color: #4d4e4c;
}

.tableHeader {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 930px) and (orientation: landscape) {
  .comparisonTable {
    top: 10vh !important;
    height: calc(100% - 10vh) !important;
    padding: 0px 1vw !important;
  }

  .comparisonTableHeader {
    align-items: center;
    padding: 2vh 0px !important;
  }

  .comparisonTableTitle {
    font-size: 4vmin !important;
  }

  .comparisonTableHeader .printComparisonTable,
  .comparisonTableHeader .backComparisonTable {
    height: 6vh !important;
    margin-right: 0px !important;
  }

  .comparisonTableContents {
    padding-top: 1vh !important;
  }

  .tableColumn {
    min-width: calc((98% - 2vw) / 5) !important;
  }

  .tableStateName {
    font-size: 3vmin !important;
    margin-bottom: 0px !important;
  }

  .tableQuetionMark {
    height: auto !important;
    width: 3vmin !important;
  }

  .binIcon {
    height: auto !important;
    width: 3vmin !important;
  }
}
