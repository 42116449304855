.faq {
  /*text-align: center;*/
  background-color: #fbfbfb;
  height: 100%;
}

.faq .topBar {
  padding-left: 0.8vw !important;
}

.faq .navBar .MuiTypography-body1 {
  letter-spacing: 0px;
}

.faq .home {
  color: black;
  text-decoration: none;
}

.faq .MuiTypography-root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

.faq .body {
  background-color: #fbfbfb;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
}

.faq .link {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.faq .resource {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  margin: 0;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  margin-bottom: 10px !important;
}

.faq .MuiCard-root {
  background-color: #eeeeee !important;
  margin-bottom: 20px;
  width: 800px;
}

.faq .MuiTypography-h4 {
  text-align: center;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.faq .MuiTypography-body2 {
  margin-bottom: 10px !important;
}

.faq .MuiListItemText-root {
  margin-left: 40px;
  color: rgba(0, 0, 0, 0.6);
}

.faq .MuiListItemText-primary {
  font-size: 0.875rem !important;
}

.faq .threeTier {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  display: block;
  width: 100%;
}

.faq .App-logo {
  height: 40vmin;
  pointer-events: none;
}

.faq .drop-down-title p {
  margin-bottom: 0px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .faq .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.faq .moveTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  color: #b21e8c;
  width: 30px;
  height: auto;
}

.faq ::marker {
  color: rgba(0, 0, 0, 0.6);
}

.faq .mobileHamburger,
.faq .mobileMenu {
  display: none;
}

@media screen and (max-width: 930px) and (orientation: landscape) {
  .faq .topBar {
    padding-top: 0px !important;
    padding-left: 0px !important;
  }

  .faq .navBar {
    min-width: 0px !important;
    margin-top: 1px !important;
  }

  .faq .MuiTypography-h4 {
    font-size: 5vmin !important;
    margin-top: 2vh !important;
    margin-bottom: 2vh !important;
  }

  .faq .dropDown {
    width: 100vw;
    width: calc(var(--vw, 1vw) * 80);
    margin-bottom: 3vh;
  }

  .faq .MuiTypography-h7 {
    margin-bottom: 1vh !important;
  }

  .faq .MuiTypography-h6 {
    font-size: 3vmin !important;
  }

  .faq .MuiTypography-body2 {
    margin-bottom: 1vh !important;
    font-size: 2vmin !important;
  }

  .faq .faqContent {
    padding: 3vh 16px !important;
  }

  .faq .mobileHamburger,
  .faq .mobileMenu {
    display: none;
  }
}

@media screen and (orientation: portrait) {
  .faq .topBar {
    padding-top: 0px !important;
    padding-left: 0px !important;
    min-height: 8vh !important;
    max-height: 8vh !important;
  }

  .faq .navBar {
    display: none !important;
  }

  .faq .mobileHamburger {
    color: black;
    height: auto;
    width: 8vmin;
    display: inline;
    position: absolute;
    right: 10px;
  }

  .faq .mobileMenu {
    position: fixed;
    z-index: 1500;
    right: -100vw;
    width: 100vw;
    height: 100vh;
    background: #fbfbfb;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: all 1s ease;
  }

  .faq .active {
    right: 0;
  }

  .faq .body {
    padding: 0 2vw;
    margin: 0;
  }

  .faq .MuiTypography-h4 {
    margin: 3vh 0 !important;
    font-size: 7vmin !important;
  }

  .faq .MuiTypography-h6 {
    font-size: 4vmin !important;
  }

  .faq .MuiTypography-body2 {
    font-size: 3.5vmin !important;
  }

  .faq .dropDown {
    width: 100% !important;
  }

  .faq .moreHelp {
    margin-bottom: 60px;
  }

  .faq .MuiListItemButton-gutters {
    padding: 8px 5px !important;
  }
}
