.MuiCard-root {
  background-color: rgb(255, 255, 255) !important;
}

.windows {
  margin-top: 3px;
}

.mac {
  margin-top: 0px;
}

.markerType {
  font-size: 0.7vw;
  margin-top: -7px;
  margin-left: 1.3vw;
}

.markerType img {
  height: auto;
  width: 0.5vw;
}

.binnyMarker,
.abcMarker,
.totalWineMarker {
  margin-left: 5px;
}

.modal.fade .modal-dialog {
  transition: unset !important;
  transform: none !important;
}

@media screen and (max-width: 930px) and (orientation: landscape) {
  .filterPopupContent .modal-content {
    padding: 5px 10px !important;
  }

  .filterPopupHeader {
    padding: 0px 5px 5px !important;
  }

  .modal-header .filterPopupTitle {
    font-size: 3vmin !important;
  }
}

@media screen and (orientation: portrait) {
  .filterOption {
    font-size: 3vmin !important;
  }

  .label .MuiCheckbox-root {
    padding: 1vmin 3px 3px 9px !important;
  }

  .radio .MuiSvgIcon-root {
    width: 3vmin !important;
  }

  .checkBox .MuiSvgIcon-root {
    width: 3vmin !important;
  }

  .markerType {
    font-size: 2.7vmin;
    margin-top: -7px;
    margin-left: 2vmin;
  }

  .markerType img {
    height: auto;
    width: 2vmin;
  }

  .binnyMarker,
  .abcMarker,
  .totalWineMarker {
    margin-left: 5px;
  }
}
