.calculators .MuiTypography-h4 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.calculators .MuiTypography-h7 {
  width: 1260px;
}

.calculators .topBar {
  padding-left: 0.8vw !important;
}

.calculators .body {
  margin-left: 5%;
  background-color: #fbfbfb;
}

body {
  background-color: #fbfbfb;
}

.calculators .navBar .MuiTypography-body1 {
  letter-spacing: 0px;
}

.calculators .link {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.calculators .excels {
  display: flex;
  margin-top: 40px;
  width: 100%;
}

.calculators .MuiCard-root {
  background-color: #eeeeee !important;
  margin: 10px 15px 0px 0px !important;
  width: 300px !important;
}

.calculators .MuiTypography-gutterBottom {
  font-size: 18px;
}

.calculators .MuiCardActionArea-root {
  height: 100%;
}

.calculators .excel {
  display: flex;
  flex-direction: row;
  margin-bottom: 13px;
  width: 100%;
}

.calculators .download {
  margin-left: 5px;
  font-size: 12px !important;
}

.calculators svg {
  width: 10px;
}

.calculators .MuiTypography-root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

.calculators .mobileHamburger,
.calculators .mobileMenu {
  display: none;
}

@media screen and (max-width: 930px) and (orientation: landscape) {
  .calculators .topBar {
    padding-top: 0px !important;
    padding-left: 0px !important;
  }

  .calculators .navBar {
    min-width: 0px !important;
    margin-top: 1px !important;
  }

  .calculators .body {
    margin-left: 2%;
    max-width: 100% !important;
  }

  .calculators .MuiTypography-h4 {
    margin-top: 2vh !important;
    margin-bottom: 2vh !important;
    font-size: 5vmin !important;
  }

  .calculators .MuiTypography-h7 {
    width: 100% !important;
    font-size: 3vmin !important;
  }

  .calculators .excels {
    margin-top: 5vh;
    width: 100%;
  }

  .calculators .MuiCard-root {
    margin: 1vh 1vw 0px 0px !important;
    width: calc(100% / 4) !important;
  }

  .calculators .calculatorCard {
    padding: 2vmin !important;
  }

  .calculators .MuiTypography-gutterBottom {
    font-size: 3vmin;
  }

  .calculators .download {
    font-size: 2vmin !important;
  }

  .excel svg {
    width: 2vmin;
  }

  .calculators .mobileHamburger,
  .calculators .mobileMenu {
    display: none;
  }
}

@media screen and (orientation: portrait) {
  .calculators .topBar {
    padding-top: 0px !important;
    padding-left: 0px !important;
    min-height: 8vh !important;
    max-height: 8vh !important;
  }

  .calculators .navBar {
    display: none !important;
  }

  .calculators .mobileHamburger {
    color: black;
    height: auto;
    width: 8vmin;
    display: inline;
    position: absolute;
    right: 10px;
  }

  .calculators .mobileMenu {
    position: fixed;
    z-index: 1500;
    right: -100vw;
    width: 100vw;
    height: 100vh;
    background: #fbfbfb;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: all 1s ease;
  }

  .calculators .active {
    right: 0;
  }

  .calculators .body {
    padding: 0 2vw;
    margin: 0;
  }

  .calculators .MuiTypography-h4 {
    margin: 3vh 0 !important;
    font-size: 7vmin !important;
  }

  .calculators .MuiTypography-h7 {
    max-width: 100% !important;
  }

  .calculators .excels {
    flex-direction: column;
    margin-top: 3vh;
    padding-bottom: 170px;
  }

  .calculators .MuiCard-root {
    margin: 10px 0px 0px 0px !important;
    width: 100% !important;
  }
}
