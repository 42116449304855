.app {
  height: 100%;
}
.app .App {
  text-align: center;
}

.app .App-logo {
  height: 40vmin;
  pointer-events: none;
}

.app .link {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.topBar {
  padding-left: 0.8vw !important;
}

.MuiTypography-root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

.MuiMenuItem-root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

.MuiTooltip-tooltipPlacementBottom {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app body {
  background-color: #efefef;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Content {
  height: 90vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered {
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  color: #8d8d8e;
  font-weight: 100;
}

.app .leaflet-control-zoom-in {
  background-image: none;
}

.app .leaflet-control-zoom-out {
  background-image: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
}

.logo {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: -webkit-crisp-edges; /* Safari 7+*/
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  width: auto;
  height: 65px;
  margin: 5px;
  transform: scale(1) rotate(0) translate3d(0, 0, 0);
}

.mobileHamburger,
.mobileMenu,
.filterIcon {
  display: none;
}

.footer {
  height: 25px;
  z-index: 2000;
}

.footerNav {
  height: 100% !important;
}

.footerNavAct {
  width: 100vw !important;
  min-width: 100vw !important;
  max-width: 100vw !important;
  color: black !important;
}

.footerNavAct .MuiBottomNavigationAction-label {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

@media screen and (max-width: 930px) and (orientation: landscape) {
  header {
    padding-top: 0px !important;
  }

  .logo {
    height: 8vmin;
    margin: 3px 5px 3px 5px !important;
  }

  .topBar {
    padding-left: 0 !important;
  }

  .topBarText {
    font-size: 1.5vmin !important;
  }

  .topBarButton {
    min-width: 0px !important;
    margin-top: 1px !important;
  }

  .MuiToolbar-root {
    min-height: 0px !important;
  }

  .drawerHeader {
    min-height: 0px !important;
    height: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
  }

  .Container {
    padding: 10vh 0px 0px 0px !important;
    height: 100% !important;
  }

  .MuiGrid-container {
    height: calc(100% + 16px) !important;
  }

  .doubleArrow {
    height: auto !important;
    width: 3vmin !important;
  }

  .openDrawer {
    transform: translateX(10vw) !important;
  }

  .closeDrawer {
    transform: none !important;
  }

  .mobileHamburger,
  .mobileMenu,
  .filterIcon {
    display: none;
  }
}

@media screen and (orientation: portrait) {
  .topBarButton {
    display: none !important;
  }

  header {
    padding-top: 0px !important;
  }

  .topBar {
    padding-left: 0 !important;
    min-height: 8vh !important;
    max-height: 8vh !important;
  }

  .mobileHamburger {
    color: black;
    height: auto;
    width: 8vmin;
    display: inline;
    position: absolute;
    right: 10px;
  }

  .filterIcon {
    color: black;
    height: auto;
    width: 7vmin;
    display: inline;
    margin-right: 10px;
    position: absolute;
    right: calc(10px + 10vmin);
  }

  .logo {
    height: 13vmin;
    image-rendering: unset;
    -ms-interpolation-mode: unset;
  }

  .drawerHeader {
    min-height: 0px !important;
    height: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
  }

  .Container {
    padding: 8vh 0px 0px 0px !important;
    height: 100% !important;
    width: 100vw !important;
  }

  .mobileMenu {
    position: fixed;
    z-index: 1500;
    right: -100vw;
    width: 100vw;
    height: 100vh;
    background: #fbfbfb;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: all 1s ease;
  }

  .active {
    right: 0;
  }

  .mobileMenuButton {
    margin-right: 10px !important;
    min-width: 0px !important;
  }

  .footerNavAct .MuiBottomNavigationAction-label {
    font-size: 2.5vw !important;
  }
}
